import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserCard from "./UserCard";

const CertificationDescription = ({ certification }) => {
  const { t } = useTranslation();
  const [creator, setCreator] = useState({});
  const [owner, setOwner] = useState({});
  const [issuer, setIssuer] = useState({});

  useEffect(() => {
    if (certification) {
      setCreator(certification.creatorId || {});
      setOwner(certification.ownerId || {});
      setIssuer({});
    }
  }, [certification]);

  return (
    <React.Fragment>
      <h4>{t("description")}</h4>

      <p className="mb-4" style={{ fontSize: "14px", color: "#bdbdbd" }}>
        {certification.description}
      </p>

      <div
        style={{
          height: 1,
          borderTop: "1px solid #BDBDBD",
          opacity: 0.1,
        }}
      ></div>

      <UserCard user={creator} role="creator" />

      <UserCard user={owner} role="owner" />

      <UserCard user={issuer} role="issuer" />
    </React.Fragment>
  );
};

export default CertificationDescription;
