import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEN from "../locales/en.json";
import translationIT from "../locales/it.json";
import { setPersistLanguage, getPersistLanguage } from "../helpers/locale";

const resources = {
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
};

const fallbackLanguage = "en";

const getInitialLanguage = () => {
  const persistLanguage = getPersistLanguage();
  if (persistLanguage && Object.keys(resources).includes(persistLanguage))
    return persistLanguage;
  else {
    setPersistLanguage(fallbackLanguage);
    return fallbackLanguage;
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: getInitialLanguage(),
    fallbackLng: fallbackLanguage,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
