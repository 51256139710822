import React from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as CopyIcon } from "../../assets/copy.svg";

const CopyLinkInput = ({
  linkText,
  className = "author__code",
  inputClassName = "",
}) => {
  return (
    <div className={className}>
      <input
        type="text"
        defaultValue={linkText}
        className={inputClassName}
        readOnly
      />

      <Button
        variant="empty"
        className="p-0"
        onClick={() => {
          navigator.clipboard.writeText(linkText || "");
        }}
      >
        <CopyIcon />
      </Button>
    </div>
  );
};

export default CopyLinkInput;
