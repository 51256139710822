import React from "react";
import CertificationGalleryActive from "./GalleryActive";
import CertificationGalleryCarousel from "./GalleryCarousel";

const CertificationGallery = ({ certificationData, active, setActive }) => {
  return (
    <div>
      <CertificationGalleryActive
        src={active || ""}
      />

      <CertificationGalleryCarousel
        certificationData={certificationData}
        setActive={setActive}
      />
    </div>
  );
};

export default CertificationGallery;
