import React, { useEffect, useState } from "react";
import UserAvatar from "../user/Avatar";

const HistoryItem = ({ historyItem }) => {
  const [description, setDescription] = useState("");

  useEffect(() => {
    setDescription(historyItem?.description || "");
  }, [historyItem]);

  return (
    <UserAvatar profile={null} avatarClass="asset__action" verified>
      <p>{description}</p>
    </UserAvatar>
  );
};

export default HistoryItem;
