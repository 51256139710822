import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.svg";

const HomePage = () => {
  const [text, setText] = useState("");
  const history = useHistory();

  const handleChange = (e) => {
    setText(e.target.value);
  };
  const sumbitChange = () => {
    history.push(`/qr/${text}`);
  };

  return (
    <Row className="sign__form centered w-100 g-0 p-5">
      <Row className="w-100 g-0">
        <Col className="w-100">
          <div className="text-center">
            <img className="logo" src={logo} alt="logo" />
          </div>
        </Col>
      </Row>
      <Row className="w-100 g-0 mt-4">
        <Col className="w-100">
          <div className="text-center">
            <input
              type="text"
              className="sign__input w-100"
              placeholder="text"
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>
      <Row className="w-100 g-0 mt-4">
        <Col className="w-100">
          <div className="text-center">
            <Button onClick={sumbitChange}>
                Next
            </Button>
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export default HomePage;
