import { combineReducers, configureStore } from "@reduxjs/toolkit";

import certificationReducer from "./CertificationSlice";
import authReducer from './AuthSlice';

const reducer = combineReducers({
  certification:certificationReducer,
  auth:authReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "store/reset") {
    state = undefined;
  }
  return reducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
