import React from "react";
import { Trans, useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/language/Switcher";
import logo from "../../assets/Esex_Logo_payoff_bianco.svg";
import lock from "../../assets/Lock and BG.png";

const PageHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="header w-100 py-5 px-3 px-md-5">
      <div className="d-flex justify-content-between">
        <img className="certification__header_logo" src={logo} alt="logo" />

        <LanguageSwitcher />
      </div>

      <div className="text-center">
        <div>
          <img src={lock} alt="lock" />
        </div>

        <h2 className="mt-4 certification__header_title">
          <Trans
            t={t}
            i18nKey="certification-page.header.title"
            components={{ span: <span /> }}
          />
        </h2>

        <div className="text-center mx-auto pb-4 certification__header_description">
          {t("certification-page.header.subtitle")}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
