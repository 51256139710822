import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { setPersistLanguage } from "../../helpers/locale";
import languages from "../../constants/languages";

const getFlag = (lang) => (languages[lang] ? languages[lang].flag : "");
const getFlagAlt = (lang) => (languages[lang] ? languages[lang].flagAlt : "");
const getLabel = (lang) => (languages[lang] ? languages[lang].label : "-");
const isLanguageValid = (langObject, lang) => {
  return Object.keys(langObject).includes(lang);
};

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const activeLang = i18n.language;

  const onChangeLanguage = (lang) => {
    if (isLanguageValid(i18n.services.resourceStore.data, lang)) {
      i18n.changeLanguage(lang);
      setPersistLanguage(lang);
    }
  };

  return (
    <Dropdown className= "footer__lang ml-3">
      <Dropdown.Toggle
        id="dropdownLang"
        className="footer__lang-btn"
        as="button"
        bsPrefix="c__none"
      >
        <img src={getFlag(activeLang)} alt={getFlagAlt(activeLang)} />
        <span>{getLabel(activeLang)}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu footer__lang-dropdown p-3"
        aria-labelledby="dropdownLang"
        style={{ minWidth: "6rem" }}
        as="ul"
      >
        {Object.keys(languages).map((lang) => (
          <Dropdown.Item
            key={lang}
            as="li"
            bsPrefix="c__none"
            style={{ cursor: "pointer" }}
            onClick={() => {
              onChangeLanguage(lang);
            }}
          >
            <Dropdown.ItemText as="a" bsPrefix="c__none">
              <img src={getFlag(lang)} alt={getFlagAlt(lang)} />
              <span>{getLabel(lang)}</span>
            </Dropdown.ItemText>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSwitcher;
