import React, { useRef } from "react";
import { Row, Col } from "react-bootstrap";
import CertificationGalleryCarouselItem from "./GalleryCarouselItem";
import arrow from "../../assets/arrow.png";

const CertificationGalleryCarousel = ({ certificationData, setActive }) => {
  const scrollRef = useRef();

  const handleScroll = (direction) => {
    /** scroll calculation: width + 2 * marginLeft
     *  width: 220px
     *  marginLeft: 16px
     *  */
    if (direction === "left") {
      return scrollRef ? (scrollRef.current.scrollLeft -= 252) : null;
    } else {
      return scrollRef ? (scrollRef.current.scrollLeft += 252) : null;
    }
  };

  return (
    <div
      style={{ position: "relative" }}
      className="d-flex align-items-center px-0 px-md-5 py-3  w-100"
    >
      <div
        className="cursor__pointer mr-3"
        onClick={() => {
          handleScroll("left");
        }}
      >
        <img src={arrow} alt="arrow" />
      </div>

      <Row
        className="w-100 noScrollThumb no-gutters flex-nowrap"
        style={{ overflowX: "scroll" }}
        ref={scrollRef}
      >
        {certificationData &&
          certificationData.files.map((image, index) => (
            <Col className="col-auto ml-3" key={index}>
              <CertificationGalleryCarouselItem
                image={image}
                setActive={setActive}
              />
            </Col>
          ))}
      </Row>

      <div
        className="cursor__pointer ml-3"
        onClick={() => {
          handleScroll("right");
        }}
      >
        <img style={{ transform: "rotate(180deg)" }} src={arrow} alt="arrow" />
      </div>
    </div>
  );
};

export default CertificationGalleryCarousel;
