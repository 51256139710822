import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { publicRoutes } from "./routes/allRoutes";

import "./plugins/i18n";

function App() {
  return (
    <Router>
      <Switch>
        {publicRoutes.map((route, index) => (
          <Route exact path={route.path} key={index}>
            {route.component}
          </Route>
        ))}
      </Switch>
    </Router>
  );
}

export default App;
