import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../services";

export const createCertification = createAsyncThunk(
  "certification/createCertification",
  async (data, { rejectWithValue }) => {
    try {
      const response = await services.AssetService.createAsset(data);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveCertifications = createAsyncThunk(
  "certification/retrieveCertifications",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.AssetService.retrieveAssets(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveCertification = createAsyncThunk(
  "certification/retrieveCertification",
  async ({ assetId }, { rejectWithValue }) => {
    try {
      const response = await services.AssetService.retrieveAsset(assetId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCertification = createAsyncThunk(
  "certification/updateCertification",
  async ({ assetId, info }, { rejectWithValue }) => {
    try {
      const response = await services.AssetService.updateAsset(assetId, info);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCertification = createAsyncThunk(
  "certification/deleteCertification",
  async (assetId, { rejectWithValue }) => {
    try {
      const response = await services.AssetService.deleteAsset(assetId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCertificationDocument = createAsyncThunk(
  "certification/createCertificationDocument",
  async ({ assetId, data }, { rejectWithValue }) => {
    try {
      const response = await services.AssetService.createAssetDocument(
        assetId,
        data
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state: "",
  error: {},
};

const certificationSlice = createSlice({
  name: "certification",
  initialState,
  reducers: {},
  extraReducers: {
    /* ---- CREATE_CERTIFICATION ---- */
    [String(createCertification.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createCertification.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createCertification.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_CERTIFICATIONS ---- */
    [String(retrieveCertifications.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveCertifications.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveCertifications.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_CERTIFICATION ---- */
    [String(retrieveCertification.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveCertification.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveCertification.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- UPDATE_CERTIFICATION ---- */
    [String(updateCertification.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(updateCertification.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(updateCertification.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- DELETE_CERTIFICATION ---- */
    [String(deleteCertification.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(deleteCertification.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(deleteCertification.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- CREATE_CERTIFICATION_DOCUMENT ---- */
    [String(createCertificationDocument.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createCertificationDocument.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createCertificationDocument.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
  },
});

export default certificationSlice.reducer;
