import React from "react";
import documentationBgImg from "../../assets/Rectangle 1.png";
import documentationRectImg from "../../assets/Rectangle 2.png";

const CertificationDocumentItem = ({ document }) => {
  const handleOpenDocument = () => {
    const documentUrl = document?.file?.sizes?.original?.url;
    if (documentUrl) window.open(documentUrl, "_blank");
  };

  return (
    <div className="mt-3" style={{ color: "#BDBDBD" }}>
      <div className="d-flex">
        <div className="mr-4" style={{ position: "relative" }}>
          <img src={documentationBgImg} alt="doc_icon" />

          <img
            style={{
              position: "absolute",
              left: "32%",
              top: "32%",
            }}
            src={documentationRectImg}
            alt="doc_icon"
          />
        </div>

        <h6
          style={{ fontSize: "22px", fontWeight: 600, cursor: "pointer" }}
          className="align-self-center ms-3"
          onClick={handleOpenDocument}
        >
          {document.name}
        </h6>
      </div>
      <p
        style={{ width: "65%", fontSize: "16px", fontWeight: 400 }}
        className="mt-3"
      >
        {document.description}
      </p>
    </div>
  );
};

export default CertificationDocumentItem;
