import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import PageHeader from "./PageHeader";
import CertificationGallery from "./Gallery";
import CertificationDescription from "./Description";
import CertificationDocuments from "./Documents";
import CertificationHistory from "./History";
import CertificationShare from "./Share";
import { retrieveCertification } from "../../store/CertificationSlice";
import { getAssetImage } from "../../helpers/asset";

import "./certification.scss";

const CertificationPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [certificationData, setCertificationData] = useState("");
  const [active, setActive] = useState("");
  const [documents, setDocuments] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const fetchCertification = useCallback(async () => {
    try {
      const response = await dispatch(
        retrieveCertification({ assetId: id })
      ).unwrap();
      const responseData = response.body.data;

      setCertificationData(responseData);
      setActive(getAssetImage(responseData));
      setDocuments(responseData.documents || []);
      setTransactions(responseData.transactions || []);
      // setTransactions([...Array(10)].map(()=>responseData.transactions[0]) || []);
    } catch (error) {
      console.log(error);
    }
  }, [id, dispatch]);

  useEffect(() => {
    fetchCertification();
  }, [fetchCertification]);

  return (
    <React.Fragment>
      <div className="text-white w-100">
        <PageHeader />
      </div>

      <div className="text-white w-100">
        <div className="py-5 px-3 px-sm-5">
          <h1 className="ml-3 ml-lg-5 certification__title">
            {certificationData.title}
          </h1>

          <Row className="no-gutters ml-n5">
            <Col className="col-12 col-lg-8 pl-5">
              <CertificationGallery
                certificationData={certificationData}
                active={active}
                setActive={setActive}
              />
            </Col>

            <Col className="col-12 col-lg-4 pl-5 mt-3 mt-lg-0">
              <CertificationDescription certification={certificationData} />
            </Col>

            <Col className="col-12 col-md-6 col-lg-4 pl-5 mt-4">
              <CertificationDocuments documents={documents} />
            </Col>

            <Col className="col-12 col-md-6 col-lg-4 pl-5 mt-4">
              <CertificationHistory transactions={transactions} />
            </Col>

            <Col className="col-12 col-lg-4 pl-5 mt-4">
              <CertificationShare certificationId={id} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CertificationPage;
