import avatarDefaultImage from "../assets/avatar5.jpg";

export { avatarDefaultImage };

export const getUserProfile = (user, profileIndex = 0) =>
  (user?.profiles?.length > 0 && user.profiles[profileIndex]?.profileId) ||
  null;

export const getUserAvatar = (
  profile,
  avatarSize = "150",
  useDefaultImage = true
) =>
  (profile?.avatar?.sizes && profile.avatar.sizes[avatarSize]?.url) ||
  (useDefaultImage ? avatarDefaultImage : "");

export const getUserEthereumAccount = (identities) => {
  let registeredAcount = {};

  if (!identities) return registeredAcount;

  const match = identities.find((identity) => identity.type === "ethereum");

  if (match) {
    registeredAcount.identity = match.identityId;
    registeredAcount.id = match._id;
  }

  return registeredAcount;
};
