import React from "react";
import { useTranslation } from "react-i18next";
import HistoryList from "../../components/history/List";

const CertificationHistory = ({ transactions }) => {
  const { t } = useTranslation();

  return (
    <div className="card__wrapper">
      <h1 className="certification__section__title text-truncate">
        {t("transactions")}
      </h1>

      <HistoryList list={transactions} containerHeight="380px" />
    </div>
  );
};

export default CertificationHistory;
