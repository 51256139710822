import React, { useMemo } from "react";
import { getAssetImageBySize } from "../../helpers/asset";

const CertificationGalleryCarouselItem = ({ image, setActive }) => {
  const src = useMemo(() => getAssetImageBySize(image), [image]);

  return (
    <div
      className="me-4 text-center cursor__pointer"
      style={{ width: 220, height: 150, position: "relative" }}
    >
      <img
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
          top: "50%",
          position: "relative",
          transform: "translateY(-50%)",
        }}
        src={src}
        alt="galleryImage"
        onClick={() => {
          setActive(src);
        }}
      />
    </div>
  );
};

export default CertificationGalleryCarouselItem;
