import React from "react";
import HomePage from "../pages/home";
import CertificationPage from "../pages/certification";

const publicRoutes = [
  { path: "/", component: <HomePage /> },
  { path: "/qr/:id", component: <CertificationPage /> },
];

const authRoutes = [];

export { publicRoutes, authRoutes };
