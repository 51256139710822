import React, { useEffect, useState } from "react";
import { Row, Col, Button, Toast, ToastBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { certificationSettings } from "../../constants/AppSettings";
import CopyLinkInput from "../../components/input/CopyLink";

const CertificationShare = ({ certificationId }) => {
  const { t } = useTranslation();
  const [qrLink, setQrLink] = useState("");
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setQrLink(`${certificationSettings.url}/qr/${certificationId}`);
  }, [certificationId]);

  return (
    <div className="card__wrapper">
      <h1 className="certification__section__title text-truncate">
        {t("share")}
      </h1>

      <div className="d-flex justify-content-center">
        <QRCode
          className="p-4 certification__share__qr"
          value={qrLink}
          style={{ backgroundColor: "white" }}
        />
      </div>

      <Row className="no-guters mt-5">
        <Col className="mb-2">
          <CopyLinkInput linkText={qrLink} className="author__code mt-0" />
        </Col>

        <Col className="col-12 col-md-auto mb-2">
          <Button
            className="px-3 py-2 m-0 sign__btn"
            style={{ height: "30px" }}
            variant="primary"
            size="sm"
            onClick={() => setShowToast(true)}
          >
            {t("download-as-pdf")}
          </Button>
        </Col>

        <Toast
          show={showToast}
          delay={2000}
          autohide
          onClose={() => setShowToast(false)}
        >
          <ToastBody style={{ backgroundColor: "black", color: "white" }}>
            <strong className="me-auto">Feature not supported yet.</strong>
          </ToastBody>
        </Toast>
      </Row>
    </div>
  );
};

export default CertificationShare;
